// @ts-ignore
import { HighchartColors } from '@trovata/app/shared/models/highcharts.model';

export const HighChartColors: HighchartColors = {
	generalChartColors: [
		'#C20000',
		'#000000',
		'#B3B3B3',
		'#E6E6E6',
		'#194D23',
		'#2C853C',
		'#A4B123',
		'#C9CB00',
		'#F1EB82',
		'#005AA3',
		'#6FB2DE',
		'#AACBE4',
		'#572381',
		'#771C7F',
		'#A92582',
		'#E9600E',
		'#F08A16',
		'#FCD59A',
	],
	forecastChartColors: ['#C20000', '#000000', '#B3B3B3', '#E6E6E6', '#194D23', '#2C853C', '#A4B123', '#C9CB00'],
	barColorScheme: [
		{
			value: 0,
			color: '#194D23',
		},
		{
			color: '#2C853C',
		},
	],
	grossColors: {
		credit: '#2C853C',
		debit: '#194D23',
		neutral: '#A0A0A0',
		lightNeutral: '#D9D9D9',
	},
	accountSummaryGradientStops: [
		[0, '#00000070'],
		[1, '#00000010'],
	],
	accountSummaryLineColor: '#000000',
};
