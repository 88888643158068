import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext, Store } from '@ngxs/store';
import { catchError, EMPTY, finalize, firstValueFrom, Observable, Subscription, tap, throwError } from 'rxjs';
import { TrovataAppState } from 'src/app/core/models/state.model';
import { SerializationService } from 'src/app/core/services/serialization.service';
import {
	InitHomeState,
	ResetHomeState,
	ClearHomeState,
	GetBalancesReport,
	GetAccountSummary,
	GetInflowsReport,
	GetOutflowsReport,
	GetTransactionsPreview,
	SetAccountSummaryLoadingState,
	SetTransactionsPreviewLoadingState,
	GetHomePreferences,
	UpdatePageViewPreferences,
	UpdateHomeDataPreferencess,
} from '../actions/home.action';
import { ReportV4, ReportV4Element } from '@trovata/app/features/reports/models/report.model';
import { AnalysisService } from '@trovata/app/features/reports/services/analysis.service';
import { AnalysisBalanceValue, AnalysisDataRoot, AnalysisTransactionValue } from '@trovata/app/features/reports/models/analysis.model';
import { TqlService } from '@trovata/app/shared/services/tql.service';
import { Cadence } from '@trovata/app/shared/models/cadence.model';
import { DateTime } from 'luxon';
import { AccountSummaryService } from '../../services/account-summary.service';
import { GroupByKey } from '@trovata/app/shared/utils/key-translator';
import { AccountSummary, defaultHomeGroupBy } from '../../models/home.model';
import { TransactionsService } from '@trovata/app/features/transactions/services/transactions.service';
import { GetTransactionsResponse } from '@trovata/app/features/transactions/models/transaction.model';
import { HttpResponse } from '@angular/common/http';
import { TransactionsPreview } from '../../models/transactions-preview.model';
import { TQLPayload, TQLPropertyKey } from '@trovata/app/shared/models/tql.model';
import { v4 as uuid } from 'uuid';
import { HomePreferences, PreferencesV2Key, PreferencesV2OwnerFlag } from '@trovata/app/shared/models/preferences.model';
import { getHomeBalanceReport, getHomeInflowsReport, getHomeOutflowsReport } from '../../models/home-reports';
import { PreferencesV2Service } from '@trovata/app/shared/services/preferencesV2.service';
import { ItemViewedEvent } from '../../models/recently-viewed';
import { firstValidValueFrom } from '@trovata/app/shared/utils/firstValidValueFrom';
import { CustomerFeatureFacadeService } from '@trovata/app/features/settings/services/facade/customer-feature.service';

export class HomeStateModel {
	accountSummary: AccountSummary;
	balancesReport: ReportV4;
	inflowsReport: ReportV4;
	outflowsReport: ReportV4;
	transactionsPreview: TransactionsPreview;
	accountSummaryLatestRequestId: string;
	balancesReportLatestRequestId: string;
	inflowsReportLatestRequestId: string;
	outflowsReportLatestRequestId: string;
	transactionsPreviewLatestRequestId: string;
	accountSummaryLoading: boolean;
	balanceReportLoading: boolean;
	inflowsReportLoading: boolean;
	outflowsReportLoading: boolean;
	transactionsPreviewLoading: boolean;
	accountSummaryErrorState: boolean;
	balanceReportErrorState: boolean;
	inflowsReportErrorState: boolean;
	outflowsReportErrorState: boolean;
	transactionsPreviewErrorState: boolean;
	preferences: HomePreferences;
	highAccountVolumeMode: boolean;
}

@State<HomeStateModel>({
	name: 'home',
	defaults: {
		accountSummary: null,
		balancesReport: null,
		inflowsReport: null,
		outflowsReport: null,
		transactionsPreview: null,
		accountSummaryLatestRequestId: null,
		balancesReportLatestRequestId: null,
		inflowsReportLatestRequestId: null,
		outflowsReportLatestRequestId: null,
		transactionsPreviewLatestRequestId: null,
		accountSummaryLoading: false,
		balanceReportLoading: false,
		inflowsReportLoading: false,
		outflowsReportLoading: false,
		transactionsPreviewLoading: false,
		accountSummaryErrorState: false,
		balanceReportErrorState: false,
		inflowsReportErrorState: false,
		outflowsReportErrorState: false,
		transactionsPreviewErrorState: false,
		preferences: null,
		highAccountVolumeMode: false,
	},
})
@Injectable()
export class HomeState {
	private appReady$: Observable<boolean>;
	private appReadySub: Subscription;

	constructor(
		private accountSummaryService: AccountSummaryService,
		private serializationService: SerializationService,
		private preferencesV2Service: PreferencesV2Service,
		private transactionsService: TransactionsService,
		private customerFeatureFacadeService: CustomerFeatureFacadeService,
		private store: Store,
		private analysisService: AnalysisService,
		private tqlService: TqlService
	) {
		this.appReady$ = this.store.select((state: TrovataAppState) => state.core.appReady);
	}

	@Selector() static homeAccountSummary(state: HomeStateModel): AccountSummary {
		return state.accountSummary;
	}

	@Selector() static homeTransactionsPreview(state: HomeStateModel): TransactionsPreview {
		return state.transactionsPreview;
	}

	@Selector() static homeBalancesReport(state: HomeStateModel): ReportV4 {
		return state.balancesReport;
	}

	@Selector() static homeInflowsReport(state: HomeStateModel): ReportV4 {
		return state.inflowsReport;
	}

	@Selector() static homeOutflowsReport(state: HomeStateModel): ReportV4 {
		return state.outflowsReport;
	}

	@Selector()
	static isLoadingAccountSummary(state: HomeStateModel): boolean {
		return state.accountSummaryLoading;
	}

	@Selector()
	static isLoadingBalancesReport(state: HomeStateModel): boolean {
		return state.balanceReportLoading;
	}

	@Selector()
	static isLoadingInflowsReport(state: HomeStateModel): boolean {
		return state.inflowsReportLoading;
	}

	@Selector()
	static isLoadingOutflowsReport(state: HomeStateModel): boolean {
		return state.outflowsReportLoading;
	}

	@Selector()
	static isLoadingTransactionsPreview(state: HomeStateModel): boolean {
		return state.transactionsPreviewLoading;
	}

	@Selector()
	static accountSummaryErrorState(state: HomeStateModel): boolean {
		return state.accountSummaryErrorState;
	}

	@Selector()
	static balancesReportErrorState(state: HomeStateModel): boolean {
		return state.balanceReportErrorState;
	}

	@Selector()
	static inflowsReportErrorState(state: HomeStateModel): boolean {
		return state.inflowsReportErrorState;
	}

	@Selector()
	static outflowsReportErrorState(state: HomeStateModel): boolean {
		return state.outflowsReportErrorState;
	}

	@Selector()
	static transactionsPreviewErrorState(state: HomeStateModel): boolean {
		return state.transactionsPreviewErrorState;
	}

	@Selector()
	static recentPageVisits(state: HomeStateModel): ItemViewedEvent[] {
		return state.preferences.recentPageVisits;
	}

	@Selector()
	static homePreferences(state: HomeStateModel): HomePreferences {
		return state.preferences;
	}

	@Selector()
	static homeAccountFilters(state: HomeStateModel): string[] {
		return state.preferences?.accountIds;
	}

	@Selector()
	static highAccountVolumeMode(state: HomeStateModel): boolean {
		return state.highAccountVolumeMode;
	}

	@Action(InitHomeState)
	async initCurrencyState(context: StateContext<HomeStateModel>): Promise<void> {
		try {
			const deserializedState: TrovataAppState = await this.serializationService.getDeserializedState();
			const homeStateIsCached: boolean = this.homeStateIsCached(deserializedState);

			this.appReadySub = this.appReady$.subscribe({
				next: async (appReady: boolean) => {
					let state: HomeStateModel = context.getState();
					if (homeStateIsCached && appReady) {
						state = deserializedState.home;
						this.resetRequestTrackers(state);
						context.patchState(state);
					} else if (!homeStateIsCached && appReady) {
						await this.setHighAccountVolumeMode(context);
						context.dispatch(new GetBalancesReport());
						context.dispatch(new GetInflowsReport());
						context.dispatch(new GetOutflowsReport());
						context.dispatch(new GetTransactionsPreview());
						context.dispatch(new GetAccountSummary());
						context.dispatch(new SetAccountSummaryLoadingState(true));
						context.dispatch(new SetTransactionsPreviewLoadingState(true));
						context.dispatch(new GetHomePreferences());
					}
				},
				error: (error: Error) => throwError(() => error),
			});
		} catch (error: any) {
			throwError(() => error);
		}
	}

	@Action(GetAccountSummary)
	getAccountSummary(context: StateContext<HomeStateModel>, action: GetAccountSummary): Promise<void> {
		return new Promise(async (resolve, reject) => {
			try {
				const requestId: string = uuid();
				context.patchState({ accountSummaryLatestRequestId: requestId });
				let accountIds: string[];
				if (action.accountIds && action.accountIds.length) {
					accountIds = action.accountIds;
				}
				const tql: object | null = await this.generateAccountIdTQL(accountIds);

				const endDate: string = this.getDefaultEndDate().toFormat('yyyy-MM-dd');
				const startDate: string = this.getDefaultStartDate().toFormat('yyyy-MM-dd');
				const cadence: Cadence = Cadence.daily;
				const primaryGroupBy: GroupByKey = action.groupByKey ?? (await this.getAccountSummaryDefaultGroupBy()) ?? defaultHomeGroupBy;
				const groupBy: GroupByKey[] = [primaryGroupBy];
				if (!groupBy.includes(GroupByKey.account)) {
					groupBy.push(GroupByKey.account);
				}
				const isHighVolume: boolean = context.getState().highAccountVolumeMode;
				if (isHighVolume && (!action.accountIds?.length || action.accountIds?.length > 1000)) {
					await this.getHighVolumeAccountSummary(context, startDate, endDate, cadence, groupBy, tql, requestId);
				} else {
					await this.fetchAccountSummary(context, startDate, endDate, cadence, groupBy, tql, requestId);
				}
				resolve();
			} catch (error) {
				reject();
			}
		});
	}

	private fetchAccountSummary(
		context: StateContext<HomeStateModel>,
		startDate: string,
		endDate: string,
		cadence: Cadence,
		groupBy: GroupByKey[],
		tql: object | null,
		requestId: string
	): Promise<void> {
		return new Promise<void>(async (resolve, reject) => {
			this.accountSummaryService
				.getAccountSummary(startDate, endDate, cadence, groupBy, tql)
				.pipe(
					tap((accountSummary: AccountSummary) => {
						const newState: HomeStateModel = context.getState();
						if (newState.accountSummaryLatestRequestId === requestId) {
							context.patchState({ accountSummary: accountSummary, accountSummaryErrorState: false });
						}
						resolve();
					}),
					catchError(error => {
						context.patchState({ accountSummaryErrorState: true });
						reject();
						return EMPTY;
					}),
					finalize(() => {
						const newState: HomeStateModel = context.getState();
						if (newState.accountSummaryLatestRequestId === requestId) {
							context.patchState({ accountSummaryLatestRequestId: null });
						}
					})
				)
				.subscribe();
		});
	}

	private getHighVolumeAccountSummary(
		context: StateContext<HomeStateModel>,
		startDate: string,
		endDate: string,
		cadence: Cadence,
		groupBy: GroupByKey[],
		tql: object | null,
		requestId: string
	): Promise<void> {
		return new Promise<void>(async (resolve, reject) => {
			this.accountSummaryService
				.getHighAccountVolumeAccountSummary(startDate, endDate, cadence, groupBy, tql)
				.pipe(
					tap((accountSummary: AccountSummary) => {
						const newState: HomeStateModel = context.getState();
						if (newState.accountSummaryLatestRequestId === requestId) {
							context.patchState({ accountSummary: accountSummary, accountSummaryErrorState: false });
						}
						resolve();
					}),
					catchError(error => {
						context.patchState({ accountSummaryErrorState: true });
						reject();
						return EMPTY;
					}),
					finalize(() => {
						const newState: HomeStateModel = context.getState();
						if (newState.accountSummaryLatestRequestId === requestId) {
							context.patchState({ accountSummaryLatestRequestId: null });
						}
					})
				)
				.subscribe();
		});
	}

	@Action(SetAccountSummaryLoadingState)
	setAccountSummaryLoadingState(context: StateContext<HomeStateModel>, action: SetAccountSummaryLoadingState): void {
		context.patchState({ accountSummaryLoading: action.isLoading });
	}

	@Action(SetTransactionsPreviewLoadingState)
	setTransactionsPreviewLoadingState(context: StateContext<HomeStateModel>, action: SetTransactionsPreviewLoadingState): void {
		context.patchState({ transactionsPreviewLoading: action.isLoading });
	}

	@Action(GetBalancesReport)
	getBalancesReport(context: StateContext<HomeStateModel>, action: GetBalancesReport): Promise<void> {
		return new Promise(async (resolve, reject) => {
			const requestId: string = uuid();
			context.patchState({ balanceReportLoading: true, balancesReportLatestRequestId: requestId });

			const endDate: string = this.getDefaultEndDate().toFormat('yyyy-MM-dd');
			const startDate: string = this.getDefaultStartDate().toFormat('yyyy-MM-dd');
			const tql: TQLPayload = await this.generateAccountIdTQL(action.accountIds);
			const balancesReport: ReportV4 = getHomeBalanceReport(startDate, endDate, tql);
			const reportElement: ReportV4Element = balancesReport.elements[0];

			this.analysisService
				.getBalanceAnalysis({
					cadence: reportElement.parameters.cadence,
					groupBy: reportElement.parameters.groupBy,
					currencyOverride: null,
					balanceProperty: reportElement.parameters.balanceProperty,
					tqlJSONExpression: reportElement.parameters.tql?.expression,
					startDate: startDate,
					endDate: endDate,
				})
				.pipe(
					tap((balancesReportData: AnalysisDataRoot<AnalysisBalanceValue>) => {
						const newState: HomeStateModel = context.getState();
						if (newState.balancesReportLatestRequestId === requestId) {
							balancesReport.reportData = [{ elementId: 'homeBalanceElement', data: balancesReportData }];
							context.patchState({ balancesReport: balancesReport, balanceReportErrorState: false });
						}
						resolve();
					}),
					catchError(error => {
						context.patchState({ balanceReportErrorState: true });
						reject(error);
						return EMPTY;
					}),
					finalize(() => {
						const newState: HomeStateModel = context.getState();
						if (newState.balancesReportLatestRequestId === requestId) {
							context.patchState({ balanceReportLoading: false });
						}
					})
				)
				.subscribe();
		});
	}

	@Action(GetInflowsReport)
	async getInflowsReport(context: StateContext<HomeStateModel>, action: GetInflowsReport): Promise<void> {
		return new Promise(async (resolve, reject) => {
			const requestId: string = uuid();
			context.patchState({ inflowsReportLoading: true, inflowsReportLatestRequestId: requestId });

			const endDate: string = this.getDefaultEndDate().toFormat('yyyy-MM-dd');
			const startDate: string = this.getDefaultStartDate().toFormat('yyyy-MM-dd');
			let tqlExpression: object = await this.generateAccountIdTQL(action.accountIds);
			tqlExpression = this.tqlService.addParameterToTQLExpression(null, TQLPropertyKey.type, ['credit']);
			const tqlPayload: TQLPayload = {
				type: 'AST',
				expression: tqlExpression,
			};
			const inflowsReport: ReportV4 = getHomeInflowsReport(startDate, endDate, tqlPayload);
			const reportElement: ReportV4Element = inflowsReport.elements[0];

			this.analysisService
				.getTransactionsAnalysis({
					cadence: reportElement.parameters.cadence,
					groupBy: [],
					currencyOverride: null,
					tqlJSONExpression: reportElement.parameters.tql?.expression,
					startDate: startDate,
					endDate: endDate,
				})
				.pipe(
					tap((balancesReportData: AnalysisDataRoot<AnalysisTransactionValue>) => {
						const newState: HomeStateModel = context.getState();
						if (newState.inflowsReportLatestRequestId === requestId) {
							inflowsReport.reportData = [{ elementId: 'homeInflowsElement', data: balancesReportData }];
							context.patchState({ inflowsReport: inflowsReport, inflowsReportErrorState: false });
						}
						resolve();
					}),
					catchError(error => {
						context.patchState({ inflowsReportErrorState: true });
						reject(error);
						return EMPTY;
					}),
					finalize(() => {
						const newState: HomeStateModel = context.getState();
						if (newState.inflowsReportLatestRequestId === requestId) {
							context.patchState({ inflowsReportLoading: false, inflowsReportLatestRequestId: null });
						}
					})
				)
				.subscribe();
		});
	}

	@Action(GetOutflowsReport)
	getOutflowsReport(context: StateContext<HomeStateModel>, action: GetOutflowsReport): Promise<void> {
		return new Promise(async (resolve, reject) => {
			const requestId: string = uuid();
			context.patchState({ outflowsReportLoading: true, outflowsReportLatestRequestId: requestId });

			const endDate: string = this.getDefaultEndDate().toFormat('yyyy-MM-dd');
			const startDate: string = this.getDefaultStartDate().toFormat('yyyy-MM-dd');
			let tqlExpression: object = await this.generateAccountIdTQL(action.accountIds);
			tqlExpression = this.tqlService.addParameterToTQLExpression(null, TQLPropertyKey.type, ['debit']);
			const tqlPayload: TQLPayload = {
				type: 'AST',
				expression: tqlExpression,
			};
			const outflowsReport: ReportV4 = getHomeOutflowsReport(startDate, endDate, tqlPayload);
			const reportElement: ReportV4Element = outflowsReport.elements[0];

			this.analysisService
				.getTransactionsAnalysis({
					cadence: reportElement.parameters.cadence,
					groupBy: [],
					currencyOverride: null,
					tqlJSONExpression: reportElement.parameters.tql?.expression,
					startDate: startDate,
					endDate: endDate,
				})
				.pipe(
					tap((balancesReportData: AnalysisDataRoot<AnalysisTransactionValue>) => {
						const newState: HomeStateModel = context.getState();
						if (newState.outflowsReportLatestRequestId === requestId) {
							outflowsReport.reportData = [{ elementId: 'homeOutflowsElement', data: balancesReportData }];
							context.patchState({ outflowsReport: outflowsReport, outflowsReportErrorState: false });
						}
						resolve();
					}),
					catchError(error => {
						context.patchState({ outflowsReportErrorState: true });
						reject(error);
						return EMPTY;
					}),
					finalize(() => {
						const newState: HomeStateModel = context.getState();
						if (newState.outflowsReportLatestRequestId === requestId) {
							context.patchState({ outflowsReportLoading: false, outflowsReportLatestRequestId: null });
						}
					})
				)
				.subscribe();
		});
	}

	@Action(GetTransactionsPreview)
	getTransactionsPreview(context: StateContext<HomeStateModel>, action: GetTransactionsPreview): Promise<void> {
		return new Promise(async (resolve, reject) => {
			const requestId: string = uuid();
			context.patchState({ transactionsPreviewLatestRequestId: requestId });

			const endDate: DateTime = this.getDefaultEndDate();
			const startDate: DateTime = this.getDefaultStartDate();
			let tqlExpression: object = await this.generateAccountIdTQL(action.accountIds);
			tqlExpression = this.tqlService.addDatesToTQLExpression(null, startDate, endDate);
			this.transactionsService
				.getTransactions({ tqlJSONExpression: tqlExpression, from: 0, size: 5 })
				.pipe(
					tap((transactions: HttpResponse<GetTransactionsResponse>) => {
						const newState: HomeStateModel = context.getState();
						if (newState.transactionsPreviewLatestRequestId === requestId) {
							const transactionsPreview: TransactionsPreview = {
								transactions: transactions.body.transactions,
								startDate: startDate.toISODate(),
								endDate: endDate.toISODate(),
							};
							context.patchState({ transactionsPreview: transactionsPreview });
							resolve();
						}
					}),
					catchError(error => {
						context.patchState({ transactionsPreviewErrorState: true });
						reject(error);
						return EMPTY;
					}),
					finalize(() => {
						const newState: HomeStateModel = context.getState();
						if (newState.transactionsPreviewLatestRequestId === requestId) {
							context.patchState({ transactionsPreviewLoading: false, transactionsPreviewLatestRequestId: null });
						}
					})
				)
				.subscribe();
		});
	}

	@Action(GetHomePreferences)
	getHomePreferences(context: StateContext<HomeStateModel>): Promise<void> {
		return new Promise<void>(async (resolve, reject) => {
			try {
				const homePreferenceV2Obj: HomePreferences = await firstValueFrom(
					this.preferencesV2Service.getV2PreferencesByKey<HomePreferences>(PreferencesV2OwnerFlag.user, PreferencesV2Key.home)
				);
				const homePreferences: HomePreferences = homePreferenceV2Obj ? homePreferenceV2Obj : { accountIds: [], recentPageVisits: [], defaultGroupBy: null };
				context.patchState({ preferences: homePreferences });
				resolve();
			} catch (error) {
				reject(error);
			}
		});
	}

	@Action(UpdatePageViewPreferences)
	updatePageViewPreferences(context: StateContext<HomeStateModel>, action: UpdatePageViewPreferences): Promise<void> {
		return new Promise<void>(async (resolve, reject) => {
			try {
				const state: HomeStateModel = context.getState();
				state.preferences.recentPageVisits = action.updatedLastViewedItems;
				context.patchState({ preferences: state.preferences });
				await firstValueFrom(this.preferencesV2Service.putV2Preference(PreferencesV2OwnerFlag.user, state.preferences, PreferencesV2Key.home));
				resolve();
			} catch (error) {
				reject(error);
			}
		});
	}

	@Action(UpdateHomeDataPreferencess)
	updateHomeAccountFilterPreferences(context: StateContext<HomeStateModel>, action: UpdateHomeDataPreferencess): Promise<void> {
		return new Promise<void>(async (resolve, reject) => {
			try {
				const state: HomeStateModel = context.getState();
				if (action.accountIds) {
					state.preferences.accountIds = action.accountIds;
				}
				if (action.groupBy) {
					state.preferences.defaultGroupBy = action.groupBy;
				}
				if (!action.accountIds && !action.groupBy) {
					resolve();
					return;
				}
				context.patchState(state);
				await firstValueFrom(this.preferencesV2Service.putV2Preference(PreferencesV2OwnerFlag.user, state.preferences, PreferencesV2Key.home));
				resolve();
			} catch (error) {
				reject(error);
			}
		});
	}

	@Action(ResetHomeState)
	resetCurrencyState(context: StateContext<HomeStateModel>): void {
		context.dispatch(new ClearHomeState());
		context.dispatch(new InitHomeState());
	}

	@Action(ClearHomeState)
	cleanCurrencyState(context: StateContext<HomeStateModel>): void {
		this.appReadySub.unsubscribe();
		const state: HomeStateModel = context.getState();
		Object.keys(state).forEach((key: string) => {
			state[key] = null;
		});
		context.patchState(state);
	}

	private async setHighAccountVolumeMode(context: StateContext<HomeStateModel>): Promise<void> {
		const accountCount: number = await firstValueFrom(this.customerFeatureFacadeService.accountCount$);
		const isHighVolume: boolean = accountCount > 1000;
		context.patchState({ highAccountVolumeMode: isHighVolume });
	}

	private async generateAccountIdTQL(accountIds?: string[]): Promise<TQLPayload> {
		if (!accountIds || !accountIds.length) {
			const homePreferences: HomePreferences = await firstValidValueFrom(this.store.select(state => state.home.preferences));
			if (homePreferences.accountIds?.length) {
				accountIds = homePreferences.accountIds;
			}
		}

		return accountIds && accountIds.length
			? {
					type: 'AST',
					expression: this.tqlService.addParameterToTQLExpression(null, TQLPropertyKey.account, accountIds),
				}
			: null;
	}

	private async getAccountSummaryDefaultGroupBy(): Promise<GroupByKey> {
		const homePreferences: HomePreferences = await firstValidValueFrom(this.store.select(state => state.home.preferences));
		return homePreferences.defaultGroupBy;
	}

	private resetRequestTrackers(state: HomeStateModel): void {
		state.accountSummaryLoading = false;
		state.balanceReportLoading = false;
		state.inflowsReportLoading = false;
		state.outflowsReportLoading = false;
		state.transactionsPreviewLoading = false;
		state.accountSummaryLatestRequestId = null;
		state.balancesReportLatestRequestId = null;
		state.inflowsReportLatestRequestId = null;
		state.outflowsReportLatestRequestId = null;
		state.transactionsPreviewLatestRequestId = null;
	}

	private getDefaultStartDate(): DateTime {
		return DateTime.now().minus({ days: 30 });
	}

	private getDefaultEndDate(): DateTime {
		return DateTime.now();
	}

	private homeStateIsCached(deserializedState: TrovataAppState): boolean {
		const deserializedHomeState: HomeStateModel | undefined = deserializedState.home;
		if (
			deserializedHomeState &&
			deserializedHomeState.accountSummary &&
			deserializedHomeState.balancesReport &&
			deserializedHomeState.inflowsReport &&
			deserializedHomeState.outflowsReport &&
			deserializedHomeState.transactionsPreview &&
			deserializedHomeState.preferences
		) {
			return true;
		} else {
			return false;
		}
	}
}
