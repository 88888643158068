import { AcctGroupTypeOption } from '@trovata/app/shared/models/balances-grouping-view.model';
import { Cadence } from '@trovata/app/shared/models/cadence.model';
import { Currency } from '@trovata/app/shared/models/currency.model';
import { CalendarSettings } from '@trovata/app/shared/models/date-range-picker.model';
import { mapGroupTypeToTQLField, TQLValuesDict } from '@trovata/app/shared/models/tql.model';
import { Formatter } from '@trovata/app/shared/utils/formatter';
import { HighChartColors } from '@trovata/app/shared/utils/highchart-colors';
import { GroupByKey } from '@trovata/app/shared/utils/key-translator';
import { getTrendClass, getTrendIcon } from '@trovata/app/shared/utils/trends';
import Highcharts from 'highcharts';
import { DateTime, DateTimeFormatOptions } from 'luxon';
import { Subject } from 'rxjs';

export interface AccountSummaryAggregatedItem {
	aggregation: AccountSummaryGrouping[];
	summary: AccountSummaryItem[];
}

export interface AccountSummary extends AccountSummaryAggregatedItem {
	currencyConverted: string;
	cadence: Cadence;
	startDate?: string;
	endDate?: string;
	groupBy: GroupByKey[];
	tql?: object;
}

export interface AccountSummaryGrouping extends AccountSummaryAggregatedItem {
	value: string;
	key: AcctGroupTypeOption;
}

export interface AccountSummaryItem {
	date: string;
	balanceConverted?: number;
	credit?: number;
	debit?: number;
	net?: number;
	metrics: AccountSummaryMetricsItem;
}

interface AccountSummaryMetricsItem {
	netPeriodChange?: number;
	netPeriodChangePercent?: number;
	creditPeriodChange?: number;
	creditPeriodChangePercent?: number;
	debitPeriodChange?: number;
	debitPeriodChangePercent?: number;
	balancePeriodChange?: number;
	balancePeriodChangePercent?: number;
	percentOfParentBalance?: number;
}

export interface IHomeAccountSummaryRequest {
	startDate: string;
	endDate: string;
	cadence: Cadence;
	groupBy: GroupByKey[];
	tql: object;
	highAccountVolume?: boolean;
}

export interface PeriodData {
	date: string;
	totalCash: number;
}

export class HomeAccountSummaryChartOptions implements Highcharts.Options {
	chart: Highcharts.ChartOptions;
	title: Highcharts.TitleOptions;
	xAxis: Highcharts.XAxisOptions;
	yAxis: Highcharts.YAxisOptions;
	tooltip: Highcharts.TooltipOptions;
	plotOptions: Highcharts.PlotOptions;
	series: Highcharts.SeriesOptionsType[];
	exporting: Highcharts.ExportingOptions;
	credits: Highcharts.CreditsOptions;
	legend: Highcharts.LegendOptions;
	annotations?: Highcharts.AnnotationsOptions[];

	private formatter: Formatter;
	private containsIntraday: boolean;

	handleMouseOver: (point: Highcharts.Point) => void;
	handleMouseClick: (point: Highcharts.Point) => void;
	handleMouseLeave: (event: MouseEvent) => void;

	mouseOverEvent$: Subject<AccountSummaryItem>;

	injectedChart: Highcharts.Chart;

	private pinnedX: number;
	private hoverX: number;
	private currentPeriod: AccountSummaryItem;
	constructor(
		private accountSummaryPeriods: AccountSummaryItem[],
		private cadence: Cadence,
		private currencyConverted: Currency
	) {
		this.mouseOverEvent$ = new Subject<AccountSummaryItem>();
		this.formatter = new Formatter();
		this.initOptions();
	}

	setData(accountSummaryPeriods: AccountSummaryItem[], cadence: Cadence): void {
		this.cadence = cadence;
		this.accountSummaryPeriods = accountSummaryPeriods;
		this.initOptions();
	}

	private initOptions(): void {
		this.containsIntraday =
			this.cadence === Cadence.daily && this.accountSummaryPeriods[this.accountSummaryPeriods.length - 1].date === DateTime.now().toFormat('yyyy-MM-dd');
		if (this.containsIntraday && this.accountSummaryPeriods.length > 1) {
			this.pinnedX = new Date(this.accountSummaryPeriods[this.accountSummaryPeriods.length - 2].date + 'T00:00:00Z').getTime();
		} else {
			this.pinnedX = new Date(this.accountSummaryPeriods[this.accountSummaryPeriods.length - 1].date + 'T00:00:00Z').getTime();
		}
		this.setCurrentX(this.pinnedX);
		this.chart = {
			type: 'line',
			animation: false,
			spacingTop: 30,
			events: {
				load: () => {
					Highcharts.addEvent(this.injectedChart.container, 'mouseout', this.handleMouseLeave);
				},
			},
		};

		this.title = {
			text: undefined,
		};

		this.xAxis = {
			type: 'datetime',
			title: {
				text: undefined,
			},
			maxPadding: 0.02,
			minPadding: 0.02,
			endOnTick: false,
			startOnTick: false,
			dateTimeLabelFormats: {
				day: '%b %e',
				week: '%b %e',
				month: '%b %Y',
				year: '%Y',
			},
			labels: {
				formatter: (a: Highcharts.AxisLabelsFormatterContextObject) => this.getAnnotationText(this.convertTimestampToDate(<number>a.value)),
				reserveSpace: true,
			},
			plotLines: [
				{
					id: 'hover-line',
					value: this.pinnedX,
					color: '#007BFF',
					width: 1,
					dashStyle: 'Dot',
					zIndex: 2,
				},
			],
			plotBands:
				this.containsIntraday && this.accountSummaryPeriods.length > 1
					? [
							{
								id: 'intraday-band',
								from: new Date(this.accountSummaryPeriods[this.accountSummaryPeriods.length - 2].date + 'T00:00:00Z').getTime(),
								to: new Date(this.accountSummaryPeriods[this.accountSummaryPeriods.length - 1].date + 'T00:00:00Z').getTime(),
								color: '#0000000F',
							},
						]
					: null,
		};

		this.annotations = [
			{
				labels: [
					{
						point: {
							x: this.pinnedX,
							y: null,
							xAxis: 0,
							yAxis: null,
						},

						text: this.getAnnotationText(this.currentPeriod.date),
						backgroundColor: '#0000000F',
						borderColor: 'transparent',
						borderRadius: 8,
						align: 'center',
						padding: 5,
						distance: 0,
						y: -32,
						style: {
							color: '#0000008F',
							fontWeight: '700',
							fontSize: '12px',
							lineHeight: '15px',
							zIndex: 1000,
						},
					},
				],
			},
		];
		this.yAxis = {
			labels: {
				enabled: true,
				formatter: (a: Highcharts.AxisLabelsFormatterContextObject) => this.formatter.formatValue(a.value, this.currencyConverted, null, true),
			},
			title: {
				text: undefined,
			},
			opposite: true,
		};

		this.legend = {
			enabled: false,
		};

		this.tooltip = {
			enabled: true,
			formatter: function () {
				return 'Click to pin date';
			},
			backgroundColor: '#707070', // Darker background
			borderRadius: 5, // Rounded corners
			style: {
				color: 'white', // White text color
				fontSize: '12px',
				fontWeight: '500',
				padding: '0',
				height: 15,
				pointerEvents: 'none', // Prevents interaction with the tooltip
			},
			positioner: function (labelWidth, labelHeight, point) {
				// Position the tooltip 20px below the mouse cursor
				const chart = this.chart;
				return {
					x: point.plotX + chart.plotLeft + 10, // Horizontal offset
					y: point.plotY + chart.plotTop + 20, // Position below the point
				};
			},
		};

		this.exporting = {
			enabled: false, // Remove Highcharts menu
		};

		this.handleMouseLeave = (event: MouseEvent) => {
			// Check if the mouse is leaving the entire chart
			if (this.accountSummaryPeriods.length > 1 && (!event.relatedTarget || !this.injectedChart.container.contains(event.relatedTarget as Node))) {
				if (this.pinnedX) {
					this.setCurrentX(this.pinnedX);
				}
			}
		};

		this.handleMouseClick = (point: Highcharts.Point) => {
			const clickX: number = point.x; // Timestamp of the hovered point
			if (clickX === this.pinnedX || this.accountSummaryPeriods.length < 2) {
				return;
			}
			this.pinnedX = clickX;
		};

		this.handleMouseOver = (point: Highcharts.Point) => {
			const chart = this.injectedChart;
			const tooltip = chart.tooltip;

			// Show custom tooltip on hover
			tooltip.refresh([point]);

			const hoverX: number = point.x; // Timestamp of the hovered point
			if (hoverX === this.hoverX || this.accountSummaryPeriods.length < 2) {
				return;
			}

			this.hoverX = hoverX;
			this.setCurrentX(hoverX);
		};

		const handleMouseOver = this.handleMouseOver.bind(this);
		const handleMouseClick = this.handleMouseClick.bind(this);

		this.plotOptions = {
			series: {
				states: {
					inactive: {
						opacity: 1,
					},
				},
				marker: {
					enabled: false,
				},
				point: {
					events: {
						mouseOver: function (this: Highcharts.Point): void {
							handleMouseOver(this);
						},
						click: function (this: Highcharts.Point): void {
							handleMouseClick(this);
						},
					},
				},
				animation: false,
				cursor: 'pointer',
			},
		};

		this.series = [
			// Solid Line with Shading
			{
				type: 'area',
				name: 'Total Cash',
				data: this.accountSummaryPeriods
					.map(d => [new Date(d.date + 'T00:00:00Z').getTime(), d.balanceConverted])
					.slice(0, this.accountSummaryPeriods.length - (this.containsIntraday ? 1 : 0)), // Solid part
				color: HighChartColors.accountSummaryLineColor,
				zoneAxis: 'x',
				fillColor: {
					linearGradient: { x1: 0, y1: 0, x2: 0, y2: 1 },
					stops: HighChartColors.accountSummaryGradientStops,
				},
				animation: false,
			},
			{
				type: 'line',
				name: 'Projected Cash',
				data: [], // Dashed part
				color: HighChartColors.accountSummaryLineColor,
				dashStyle: 'ShortDash',
				animation: false,
			},
			{
				type: 'line',
				name: 'Projected Cash',
				data: this.accountSummaryPeriods
					.map(d => [new Date(d.date + 'T00:00:00Z').getTime(), d.balanceConverted])
					.slice(this.accountSummaryPeriods.length - (this.containsIntraday ? 2 : 1)), // Dashed part
				color: 'grey',
				dashStyle: 'ShortDash',
				animation: false,
			},
		];
		this.credits = {
			enabled: false,
		};
		if (this.injectedChart?.series) {
			this.injectedChart?.redraw();
		}
	}

	private convertTimestampToDate(timestamp: number): string {
		return DateTime.fromMillis(timestamp, { zone: 'utc' }).toFormat('yyyy-MM-dd');
	}

	private getAnnotationText(currentDate: string): string {
		const date: DateTime = DateTime.fromISO(currentDate);

		const dateFormat: DateTimeFormatOptions = { month: 'short', day: 'numeric' };

		switch (this.cadence) {
			case Cadence.daily:
				return date.toLocaleString(dateFormat);

			case Cadence.weekly:
				return `${date.toLocaleString(dateFormat)} - ${date.plus({ days: 6 }).toLocaleString(dateFormat)}`;

			case Cadence.monthly:
				return `${date.toLocaleString(dateFormat)} - ${date.endOf('month').toLocaleString(dateFormat)}`;

			case Cadence.quarterly:
				return `${date.toLocaleString(dateFormat)} - ${date.endOf('quarter').toLocaleString(dateFormat)}`;
		}
	}

	private setCurrentX(x: number): void {
		this.currentPeriod = this.accountSummaryPeriods.find(d => new Date(d.date + 'T00:00:00Z').getTime() === x);
		// Update the solid area (left side)
		if (this.injectedChart?.series) {
			this.injectedChart.series[0].setData(
				this.accountSummaryPeriods
					.filter(
						d =>
							new Date(d.date + 'T00:00:00Z').getTime() <= x &&
							new Date(d.date + 'T00:00:00Z').getTime() <=
								new Date(this.accountSummaryPeriods[this.accountSummaryPeriods.length - (this.containsIntraday ? 2 : 1)].date).getTime()
					)
					.map(d => [new Date(d.date + 'T00:00:00Z').getTime(), d.balanceConverted]),
				false
			);
			// Update the dashed projection (right side)
			this.injectedChart.series[1].setData(
				this.accountSummaryPeriods
					.filter(
						d =>
							new Date(d.date + 'T00:00:00Z').getTime() >= x &&
							new Date(d.date + 'T00:00:00Z').getTime() <=
								new Date(this.accountSummaryPeriods[this.accountSummaryPeriods.length - (this.containsIntraday ? 2 : 1)].date).getTime()
					)
					.map(d => [new Date(d.date + 'T00:00:00Z').getTime(), d.balanceConverted]),
				false
			);
			// Update the grey dashed projection (right side)
			// only if using daily cadence and this.accountSummaryPeriods[this.accountSummaryPeriods.length - 1].date is today
			this.injectedChart.series[2].setData(
				this.accountSummaryPeriods
					.filter(
						d =>
							new Date(d.date + 'T00:00:00Z').getTime() >=
							new Date(this.accountSummaryPeriods[this.accountSummaryPeriods.length - (this.containsIntraday ? 2 : 1)].date + 'T00:00:00Z').getTime()
					)
					.map(d => [new Date(d.date + 'T00:00:00Z').getTime(), d.balanceConverted]),
				false
			);

			this.injectedChart.xAxis[0].removePlotLine('hover-line');
			this.injectedChart.xAxis[0].addPlotLine({
				id: 'hover-line',
				value: x,
				color: HighChartColors.accountSummaryLineColor,
				width: 1,
				dashStyle: 'Dot',
				zIndex: 2,
			});
			this.injectedChart?.update({
				annotations: [
					{
						labels: [
							{
								point: {
									x: x,
									y: null,
									xAxis: 0,
									yAxis: null,
								},
								text: this.getAnnotationText(this.currentPeriod.date),
							},
						],
					},
				],
			});
			this.mouseOverEvent$.next(this.currentPeriod);
			this.injectedChart.redraw();
		}
	}
}

export class HomeAccountSummaryGrouping {
	groupName: string;
	groupId: string;
	groupPercentageDisplay: string;
	groupPercentValue: number;
	groupDisplayValue: string;
	groupPeriodChangePercentDisplay: string;
	groupTrendClass: string;
	groupIcon: string;
	groupTrendIcon: string;

	data: AccountSummaryGrouping;

	private formatter: Formatter;

	constructor(homeAccountSummaryGrouping: AccountSummaryGrouping, selectedDate: string, tqlValuesDict: TQLValuesDict, currency: Currency) {
		this.formatter = new Formatter();
		const dateIndex: number = homeAccountSummaryGrouping.summary.findIndex((summaryPeriod: AccountSummaryItem) => summaryPeriod.date === selectedDate);
		this.groupId = homeAccountSummaryGrouping.value;
		this.data = homeAccountSummaryGrouping;
		const key: string = mapGroupTypeToTQLField(homeAccountSummaryGrouping.key);
		// temp fix for broken nab test user
		if (!tqlValuesDict[key].values.find(value => value.id === homeAccountSummaryGrouping.value) && homeAccountSummaryGrouping.value !== 'null-key') {
			this.groupName = homeAccountSummaryGrouping.value;
		} else {
			this.groupName =
				homeAccountSummaryGrouping.value === 'null-key'
					? 'Other Balances'
					: tqlValuesDict[key].values.find(value => value.id === homeAccountSummaryGrouping.value).displayValue || this.groupId;
		}

		this.groupPercentValue = homeAccountSummaryGrouping.summary[dateIndex].metrics.percentOfParentBalance ?? 0;
		this.groupPercentageDisplay =
			homeAccountSummaryGrouping.summary[dateIndex].metrics.percentOfParentBalance === null
				? 'N/A'
				: homeAccountSummaryGrouping.summary[dateIndex].metrics.percentOfParentBalance + '%';
		this.groupDisplayValue = this.formatter.formatValue(homeAccountSummaryGrouping.summary[dateIndex].balanceConverted, currency, null, null, true);
		this.groupPeriodChangePercentDisplay =
			homeAccountSummaryGrouping.summary[dateIndex].metrics.balancePeriodChangePercent === null
				? 'N/A'
				: homeAccountSummaryGrouping.summary[dateIndex].metrics.balancePeriodChangePercent + '%';
		this.groupTrendClass = getTrendClass(homeAccountSummaryGrouping.summary[dateIndex].metrics.balancePeriodChangePercent);
		this.groupIcon = getTrendIcon(homeAccountSummaryGrouping.summary[dateIndex].metrics.balancePeriodChangePercent);
	}
}

export const defaultHomeCadence: Cadence = Cadence.daily;
export const defaultHomeGroupBy: GroupByKey = GroupByKey.institution;
export const getDefaultHomeCalendarSettings = (): CalendarSettings =>
	new CalendarSettings(DateTime.now().minus({ days: 30 }).toFormat('yyyy-MM-dd'), DateTime.now().toFormat('yyyy-MM-dd'));
