import { LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule } from '@angular/common/http';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { NgxsModule, NGXS_PLUGINS, Store } from '@ngxs/store';
import { TROVATA_APP_STATES } from './core/models/state.model';
import { NgxsStoragePluginModule, STORAGE_ENGINE } from '@ngxs/storage-plugin';
import { NgxsReduxDevtoolsPluginModule } from '@ngxs/devtools-plugin';
import { logoutPlugin } from './core/store/plugins/logout.plugin';
import { IndexedDBStorageEngine } from './core/store/storage/indexeddb-storage-engine';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { CommonModule, registerLocaleData } from '@angular/common';
import { MatNativeDateModule } from '@angular/material/core';
import { provideLottieOptions } from 'ngx-lottie';
import { AuthenticationModule } from './core/modules/authentication.module';
import { environment } from '@trovata/environments/environment';
import { ENVIRONMENT_TOKEN, STORE_TOKEN } from './core/models/config.model';
import { provideEnvironmentNgxMask } from 'ngx-mask';
import { WorkerPoolManagerService } from './core/services/worker-pool-manager.service';
import localeEsMX from '@angular/common/locales/es-MX';
import localeEs419 from '@angular/common/locales/es-419';

registerLocaleData(localeEsMX, 'es-MX');
registerLocaleData(localeEs419, 'es-419');

@NgModule({
	declarations: [AppComponent],
	imports: [
		BrowserModule,
		CommonModule,
		BrowserAnimationsModule,
		HttpClientModule,
		AppRoutingModule,
		AuthenticationModule,
		NgxsModule.forRoot(TROVATA_APP_STATES, {
			developmentMode: false,
		}),
		NgxsStoragePluginModule.forRoot({
			deserialize: state => state,
			serialize: state => state,
		}),
		NgxsReduxDevtoolsPluginModule.forRoot({
			disabled: environment.production ? true : false,
		}),
		MatSnackBarModule,
		MatNativeDateModule,
	],
	providers: [
		{
			provide: ENVIRONMENT_TOKEN,
			useValue: environment,
		},
		{
			provide: STORE_TOKEN,
			useClass: Store,
		},
		{
			provide: NGXS_PLUGINS,
			useValue: logoutPlugin,
			multi: true,
		},
		{
			provide: STORAGE_ENGINE,
			useClass: IndexedDBStorageEngine,
			deps: [WorkerPoolManagerService],
		},
		{
			provide: LOCALE_ID,
			useValue: navigator.language,
		},
		provideLottieOptions({ player: () => import('lottie-web') }),
		provideEnvironmentNgxMask(),
	],
	bootstrap: [AppComponent],
})
export class AppModule {}
