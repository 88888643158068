import { Injectable } from '@angular/core';
import { Select } from '@ngxs/store';
import { CustomerFeatureState } from '../../store/state/customer-feature.state';
import { Observable } from 'rxjs';

@Injectable({
	providedIn: 'root',
})
export class CustomerFeatureFacadeService {
	@Select(CustomerFeatureState.accountCount) accountCount$: Observable<number>;

	constructor() {}
}
