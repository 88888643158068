import { Injectable } from '@angular/core';
import { AccountSummary, AccountSummaryAggregatedItem, AccountSummaryGrouping, IHomeAccountSummaryRequest } from '../models/home.model';
import { catchError, forkJoin, map, Observable, Subscriber, throwError } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from '@trovata/environments/environment';
import { Cadence } from '@trovata/app/shared/models/cadence.model';
import { GroupByKey } from '@trovata/app/shared/utils/key-translator';
import { DateRange } from '@angular/material/datepicker';
import { DateTime } from 'luxon';
import { RequestThrottler } from '../../reports/utils/request-throttle';

@Injectable({
	providedIn: 'root',
})
export class AccountSummaryService extends RequestThrottler {
	constructor(private httpClient: HttpClient) {
		super(30);
	}

	getAccountSummary(startDate: string, endDate: string, cadence: Cadence, groupBy: GroupByKey[], tql?: object): Observable<AccountSummary> {
		const body: IHomeAccountSummaryRequest = { startDate, endDate, tql, cadence, groupBy };
		const url: string = `${environment.trovataAPI('workspace')}` + '/data/v5/accounts/summary';

		return new Observable<AccountSummary>(observer => {
			const request: Observable<Promise<AccountSummary>> = this.httpClient
				.post<AccountSummary>(url, body, {
					observe: 'response',
				})
				.pipe(
					map(async resp => {
						observer.next(resp.body);
						observer.complete();
						return resp.body;
					}),
					catchError(err => {
						observer.error(err);
						return throwError(() => err);
					})
				);
			this.addRequestToQueue(request);
		});
	}

	getHighAccountVolumeAccountSummary(startDate: string, endDate: string, cadence: Cadence, groupBy: GroupByKey[], tql?: object): Observable<AccountSummary> {
		return new Observable<AccountSummary>((observer: Subscriber<AccountSummary>) => {
			const dateRangeSegments: DateRange<string>[] = this.getHighVolumeDateRangeSegments(startDate, endDate, cadence);
			const accountSummaryRequests: Observable<AccountSummary>[] = dateRangeSegments.map((dateRange: DateRange<string>) =>
				this.getAccountSummary(dateRange.start, dateRange.end, cadence, groupBy, tql)
			);
			forkJoin(accountSummaryRequests)
				.pipe(
					map((responses: AccountSummary[]): AccountSummary => {
						const mergedAccountSummary: AccountSummary = this.mergeAccountSummaries(responses);
						mergedAccountSummary.startDate = startDate;
						mergedAccountSummary.endDate = endDate;
						observer.next(mergedAccountSummary);
						observer.complete();
						return mergedAccountSummary;
					}),
					catchError(err => {
						observer.error(err);
						return throwError(() => err);
					})
				)
				.subscribe();
		});
	}

	private getHighVolumeDateRangeSegments(startDate: string, endDate: string, cadence: Cadence): DateRange<string>[] {
		const dateRangeSegments: DateRange<string>[] = [];
		const start: DateTime = DateTime.fromISO(startDate);
		const end: DateTime = DateTime.fromISO(endDate);

		const periodStarts: DateTime[] = [];

		let current: DateTime;
		switch (cadence) {
			case Cadence.daily:
				current = start;
				while (current <= end) {
					periodStarts.push(current);
					current = current.plus({ days: 1 });
				}
				break;

			case Cadence.weekly:
				current = start.weekday === 1 ? start : start.plus({ days: 8 - start.weekday }); // next Monday
				while (current.plus({ days: 6 }) <= end) {
					periodStarts.push(current);
					current = current.plus({ weeks: 1 });
				}
				break;

			case Cadence.monthly:
				current = start.startOf('month');
				while (current.endOf('month') <= end) {
					periodStarts.push(current);
					current = current.plus({ months: 1 });
				}
				break;

			case Cadence.quarterly:
				current = DateTime.fromObject({
					year: start.year,
					month: Math.floor((start.month - 1) / 3) * 3 + 1,
					day: 1,
				});
				while (current.plus({ months: 3 }).minus({ days: 1 }) <= end) {
					periodStarts.push(current);
					current = current.plus({ months: 3 });
				}
				break;
		}

		if (periodStarts.length === 0) {
			return [new DateRange<string>(startDate, endDate)];
		}

		if (periodStarts.length === 1) {
			const onlyStart: DateTime = periodStarts[0];
			const onlyEnd: DateTime = this.getPeriodEnd(onlyStart, cadence);
			const range: DateRange<string> = new DateRange<string>(onlyStart.toISODate(), onlyEnd.toISODate());
			return [range];
		}

		let i: number = 0;
		while (i < periodStarts.length - 1) {
			let chunkSize: number = Math.min(10, periodStarts.length - i);
			const remaining: number = periodStarts.length - i;

			// If exactly 1 period would be left after this chunk, include it in this one (this one goes to 11🤘)
			if (remaining - chunkSize === 1) {
				chunkSize += 1;
			}
			const chunkStart: DateTime = i === 0 ? start : periodStarts[i]; // force given startDate on first range
			const lastPeriodStart: DateTime = periodStarts[i + chunkSize - 1];
			const chunkEndPeriod: DateTime = this.getPeriodEnd(lastPeriodStart, cadence);
			const chunkEnd: DateTime = i + chunkSize >= periodStarts.length ? end : chunkEndPeriod; // force given endDate on last range

			const range: DateRange<string> = new DateRange<string>(chunkStart.toISODate(), chunkEnd.toISODate());
			dateRangeSegments.push(range);

			i += chunkSize - 1;
		}

		return dateRangeSegments;
	}

	private getPeriodEnd(start: DateTime, cadence: Cadence): DateTime {
		switch (cadence) {
			case Cadence.daily:
				return start;
			case Cadence.weekly:
				return start.plus({ days: 6 });
			case Cadence.monthly:
				return start.endOf('month');
			case Cadence.quarterly:
				return start.plus({ months: 3 }).minus({ days: 1 });
			default:
				throw new Error(`Unsupported cadence: ${cadence}`);
		}
	}

	private mergeAccountSummaries(accountSummaries: AccountSummary[]): AccountSummary {
		const mergedAccountSummary: AccountSummary = accountSummaries[0];
		for (let i: number = 1; i < accountSummaries.length; i++) {
			const accountSummary: AccountSummary = accountSummaries[i];
			this.appendPeriodsFromAggregationItem(accountSummary, mergedAccountSummary);
		}
		return mergedAccountSummary;
	}

	private appendPeriodsFromAggregationItem(fromSummary: AccountSummaryAggregatedItem, toSummary: AccountSummaryAggregatedItem): void {
		const fromGroupings: AccountSummaryGrouping[] = fromSummary.aggregation;
		const toGroupings: AccountSummaryGrouping[] = toSummary.aggregation;
		toSummary.summary.push(...fromSummary.summary.slice(1));
		for (let i: number = 0; i < fromGroupings.length; i++) {
			const fromGrouping: AccountSummaryGrouping = fromGroupings[i];
			const toGrouping: AccountSummaryGrouping = toGroupings.find((grouping: AccountSummaryGrouping) => grouping.value === fromGrouping.value);
			if (toGrouping) {
				this.appendPeriodsFromAggregationItem(fromGrouping, toGrouping);
			} else {
				throw new Error('Something went wrong, account summary responses may be incompatible');
			}
		}
	}
}
