import { HttpClient, HttpHeaders, HttpParams, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subject, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { Cadence } from 'src/app/shared/models/cadence.model';
import { ReconciliationReport, ReconData, ReconRequestBody } from '../models/reconciliation.model';
import { DateTime } from 'luxon';

@Injectable({
	providedIn: 'root',
})
export class ReconciliationService {
	triggerSnackRefresh$: Subject<string> = new Subject();

	constructor(private httpClient: HttpClient) {}

	/**
	 * @description Returns an Observable that will have the reconciliation data.
	 * @return {todo}  The standard return object we have been using for requests.
	 */
	getReconciliationDataById(
		reconId: string,
		periodView?: boolean,
		endDate?: string,
		groupBy?: string,
		positionType?: string,
		includeFullAccountNumbers: boolean = false,
		periods?: number,
		cadence?: Cadence,
		currencyOverride?: string,
		isManual: boolean = false
	): Observable<HttpResponse<ReconData>> {
		let parameters = new HttpParams();
		if (periodView) {
			parameters = parameters.append('periodView', 'true');
			if (periods) {
				parameters = parameters.append('periods', periods.toString());
			}
			if (cadence) {
				parameters = parameters.append('cadence', cadence);
			}
			if (currencyOverride) {
				parameters = parameters.append('currencyOverride', currencyOverride);
			}
		}
		if (endDate) {
			parameters = parameters.append('endDate', endDate);
		}
		if (groupBy) {
			parameters = parameters.append('groupBy', groupBy);
		}
		if (isManual) {
			parameters = parameters.append('isManual', 'true');
		}
		if (includeFullAccountNumbers) {
			parameters = parameters.append('includeFullAccountNumbers', 'true');
		}
		if (positionType) {
			parameters = parameters.append('positionType', positionType);
		}
		const url = `${environment.trovataAPI('workspace')}/data/reports/recon/${reconId}/data`;
		return this.httpClient
			.post<ReconData>(
				url,
				{},
				{
					params: parameters,
					observe: 'response',
				}
			)
			.pipe(catchError(err => throwError(() => err)));
	}

	/**
	 * @description Returns an Observable that will have the reconciliation data.
	 * @return {todo}  The standard return object we have been using for requests.
	 */
	getReconciliationData(
		periodView: boolean,
		body: ReconRequestBody,
		endDate: string,
		groupBy: string,
		positionType?: string,
		includeFullAccountNumbers: boolean = false,
		cadence?: Cadence,
		periods?: number,
		currency?: string,
		isManual: boolean = false
	) {
		const url = `${environment.trovataAPI('workspace')}/data/reports/recon/data`;
		let parameters = new HttpParams();
		parameters = parameters.append('endDate', endDate);
		parameters = parameters.append('groupBy', groupBy);
		if (isManual) {
			parameters = parameters.append('institutionType', 'MANUAL');
		}
		if (includeFullAccountNumbers) {
			parameters = parameters.append('includeFullAccountNumbers', 'true');
		}
		if (positionType) {
			parameters = parameters.append('positionType', positionType);
		}

		if (periodView) {
			parameters = parameters.append('periodView', 'true');
			parameters = parameters.append('periods', periods.toString());
			parameters = parameters.append('cadence', cadence);
			parameters = parameters.append('currencyOverride', currency);
		}
		return this.httpClient
			.post(url, body, {
				params: parameters,
				observe: 'response',
			})
			.pipe(catchError(err => throwError(() => err)));
	}

	/**
	 * @description Returns an Observable that will have one reconciliation reports for a given reconId.
	 * @return {todo}  The standard return object we have been using for requests.
	 */
	getReconciliationReports(): Observable<HttpResponse<ReconciliationReport[]>> {
		const url = `${environment.trovataAPI('workspace')}/data/reports/recon-list`;
		return this.httpClient
			.post<ReconciliationReport[]>(
				url,
				{},
				{
					observe: 'response',
				}
			)
			.pipe(catchError(err => throwError(() => err)));
	}

	/**
	 * @description  Edits the recon report for the provided information
	 * @return {todo}  The standard return object we have been using for requests.
	 */
	putReconciliationReport(report: ReconciliationReport): Observable<HttpResponse<ReconciliationReport>> {
		const url = `${environment.trovataAPI('workspace')}/data/reports/recon/${report.reconId}`;
		let parameters = new HttpParams();
		if (report.groupBy) {
			parameters = parameters.append('groupBy', report.groupBy);
		}
		if (report.endDate) {
			parameters = parameters.append('endDate', DateTime.fromISO(report.endDate).toFormat('yyyy-MM-dd'));
		}
		if (report.cadence) {
			parameters = parameters.append('cadence', report.cadence);
		}
		if (report.name) {
			parameters = parameters.append('name', report.name);
		}
		if (report.periods) {
			parameters = parameters.append('periods', report.periods.toString());
		}
		if (report.includeFullAccountNumbers) {
			parameters = parameters.append('includeFullAccountNumbers', report.includeFullAccountNumbers.toString());
		}
		if (report.isManual) {
			parameters = parameters.append('institutionType', 'MANUAL');
		}
		return this.httpClient
			.put<ReconciliationReport>(url, report, {
				params: parameters,
				observe: 'response',
			})
			.pipe(catchError(err => throwError(() => err)));
	}

	/**
	 * @description Deletes the recon report associated with the sent ID
	 * @param {string} reconId The ID of the recon report to delete
	 * @return {Observable<HttpResponse<Object>>}  The standard return object we have been using for requests.
	 */
	deleteReconciliationReport(reconId: string): Observable<HttpResponse<Object>> {
		const url = `${environment.trovataAPI('workspace')}/data/reports/recon/${reconId}`;
		return this.httpClient
			.delete(url, {
				observe: 'response',
			})
			.pipe(catchError(err => throwError(() => err)));
	}

	/**
	 * @description Duplicates the recon report associated with the sent ID, returns newly created ID for navigation
	 * @param {string} reconId The ID of the recon report to duplicate
	 * @return {Observable<HttpResponse<Object>>}  The standard return object we have been using for requests.
	 */
	duplicateReconReport(reconId: string, newName: string): Observable<HttpResponse<ReconciliationReport>> {
		const url = `${environment.edgeAPI()}/reports/recon/duplicate/${reconId}`;
		return this.httpClient
			.post<ReconciliationReport>(
				url,
				{ newName: newName },
				{
					headers: new HttpHeaders({
						// eslint-disable-next-line @typescript-eslint/naming-convention
						'Accept-Version': 'v4',
					}),
					observe: 'response',
				}
			)
			.pipe(catchError(err => throwError(() => err)));
	}

	/**
	 * @description Creates a recon report for the provided information
	 * @return {Observable<HttpResponse<Object>>}  The standard return object we have been using for requests.
	 */
	public createReconciliationReport(report: ReconciliationReport): Observable<HttpResponse<ReconciliationReport>> {
		const url = `${environment.trovataAPI('workspace')}/data/reports/recon`;

		let parameters = new HttpParams();
		if (report.groupBy) {
			parameters = parameters.append('groupBy', report.groupBy);
		}
		if (report.endDate) {
			parameters = parameters.append('endDate', DateTime.fromISO(report.endDate).toFormat('yyyy-MM-dd'));
		}
		if (report.cadence) {
			parameters = parameters.append('cadence', report.cadence);
		}
		if (report.name) {
			parameters = parameters.append('name', report.name);
		}
		if (report.periods) {
			parameters = parameters.append('periods', report.periods.toString());
		}
		if (report.includeFullAccountNumbers) {
			parameters = parameters.append('includeFullAccountNumbers', report.includeFullAccountNumbers.toString());
		}
		if (report.isManual) {
			parameters = parameters.append('institutionType', 'MANUAL');
		}
		return this.httpClient
			.post<ReconciliationReport>(url, report, {
				params: parameters,
				observe: 'response',
			})
			.pipe(catchError(err => throwError(() => err)));
	}
}
