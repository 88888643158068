import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { AccountPutPayload, AccountsObjectV3, AccountTarget, AccountTargetV3 } from '../models/account-target.model';
import { forkJoin, Observable, of, Subject, throwError } from 'rxjs';
import { Store } from '@ngxs/store';
import { UpdateFilter } from 'src/app/shared/store/actions/filter-object.actions';
import { TransactionFilterType } from '../models/transaction-filters.model';
import { BalanceGetApiRequestBody, BalanceGetApiResponse } from '../models/historical-balance.model';
import { RequestThrottler } from '../../features/reports/utils/request-throttle';
import { GetValues } from '../store/actions/tql-fields.actions';
import { TQLPropertyKey } from '../models/tql.model';

@Injectable({
	providedIn: 'root',
})
export class AccountsService extends RequestThrottler {
	accountUpdated$: Subject<AccountTargetV3 | AccountTarget>;

	constructor(
		private httpClient: HttpClient,
		private store: Store
	) {
		super(30);
		this.accountUpdated$ = new Subject<AccountTargetV3 | AccountTarget>();
	}

	public async getAccount(accountId: string): Promise<AccountsObjectV3> {
		const accountsCall = this.getAccounts();
		const accounts = await accountsCall.toPromise();
		accounts.body.accounts = [accounts.body.accounts.find(x => x.accountId === accountId)];
		return accounts.body;
	}

	public getAccounts(
		fullAccountNumbers: boolean = false,
		accountIds?: string[],
		start?: number,
		size?: number,
		currencyOverride?: string,
		includeClosedAccounts?: boolean
	): Observable<HttpResponse<AccountsObjectV3>> {
		const maxAccountsList = 1000;

		const body: object = {};
		if (accountIds) {
			body['accountId'] = accountIds;
		}
		body['start'] = start || 0;
		if (fullAccountNumbers) {
			body['includeFullAccountNumbers'] = fullAccountNumbers;
		}
		if (currencyOverride) {
			body['currencyOverride'] = currencyOverride;
		}
		body['from'] = 0;
		body['size'] = maxAccountsList;
		if (includeClosedAccounts !== undefined) {
			body['isClosed'] = includeClosedAccounts;
		}

		const url = environment.trovataAPI('workspace') + '/data/accounts';
		return this.httpClient
			.post<AccountsObjectV3>(url, body, {
				observe: 'response',
			})
			.pipe(
				map(result => result),
				switchMap(result => {
					const totalAccounts = result.body.totalAccounts;
					let accountsLength = 0;
					const requests: Observable<HttpResponse<AccountsObjectV3>>[] = [];
					const firstResult = of(result);
					requests.push(firstResult);
					if (totalAccounts > maxAccountsList) {
						for (let i = maxAccountsList; i < totalAccounts && (!size || accountsLength < size); i += maxAccountsList) {
							const reqBody = JSON.parse(JSON.stringify(body));
							reqBody['from'] = i;
							const currentLength = size ? Math.min(size - accountsLength, maxAccountsList) : maxAccountsList;
							reqBody['size'] = currentLength;
							accountsLength += currentLength;
							requests.push(
								this.httpClient
									.post<AccountsObjectV3>(url, reqBody, {
										observe: 'response',
									})
									.pipe(catchError(err => throwError(err)))
							);
						}
					}
					return forkJoin(requests);
				}),
				map(responses => {
					const firstGroup = responses[0];
					if (responses.length > 1) {
						responses.forEach((response, index) => {
							if (index > 0) {
								firstGroup.body.accounts = [...firstGroup.body.accounts, ...response.body.accounts];
							}
						});
					}
					return firstGroup;
				}),
				catchError(err => throwError(err))
			);
	}

	public async putAccount(accountTarget: AccountTarget): Promise<any> {
		const url = `${environment.trovataAPI('workspace')}/data/accounts/${accountTarget.accountId}`;

		const payload: AccountPutPayload = {
			nickname: accountTarget.nickname,
			targetBalance: accountTarget.targetBalance,
			includeInCashForecast: accountTarget.includeInCashForecast,
			type: accountTarget.type,
			entityGroupingId: accountTarget.entityGroupingId,
			divisionGroupingId: accountTarget.divisionGroupingId,
			regionGroupingId: accountTarget.regionGroupingId,
		};

		return this.httpClient
			.put(url, payload, {
				observe: 'response',
			})
			.toPromise()
			.then(() => {
				this.accountUpdated$.next(accountTarget);
				setTimeout(() => {
					this.store.dispatch(new UpdateFilter('transaction', TransactionFilterType.accounts));
					// may be able to remove GetValues action from set timeout but needs testing
					this.store.dispatch(new GetValues([TQLPropertyKey.account]));
				}, 3000);
			});
	}

	public patchAccount(accountId: string, fieldsToUpdate: Partial<AccountTargetV3>): Observable<HttpResponse<AccountsObjectV3>> {
		const url: string = `${environment.trovataAPI('workspace')}/data/accounts/${accountId}`;

		return this.httpClient.patch<AccountsObjectV3>(url, fieldsToUpdate, { observe: 'response' }).pipe(
			tap(() => {
				setTimeout(() => {
					this.store.dispatch(new UpdateFilter('transaction', TransactionFilterType.accounts));
					this.store.dispatch(new GetValues([TQLPropertyKey.account]));
				}, 3000);
			})
		);
	}

	getHistoricalBalance(
		startDate: string,
		endDate: string,
		accountId: string,
		isManual: boolean,
		fullAccountNumbers?: boolean
	): Observable<BalanceGetApiResponse> {
		const url = environment.trovataAPI('workspace') + '/data/accounts/balances';
		const body: BalanceGetApiRequestBody = {
			startDate,
			endDate,
			accountId: [accountId],
			zeroImputeBalances: true,
			fullAccountNumbers: fullAccountNumbers,
		};
		if (isManual) {
			body.isManual = true;
			body.institutionType = 'MANUAL';
		}
		return new Observable<BalanceGetApiResponse>(observer => {
			const request = this.httpClient
				.post(url, body, {
					observe: 'response',
				})
				.pipe(
					map(resp => {
						observer.next(<BalanceGetApiResponse>resp.body);
						observer.complete();
						return resp;
					}),
					catchError(err => {
						observer.error(err);
						return throwError(err);
					})
				);
			this.addRequestToQueue(request);
		});
	}

	public getAccountsV5(
		fullAccountNumbers: boolean = false,
		accountIds?: string[],
		excludeAccountIds?: string[],
		start?: number,
		size?: number,
		currencies?: string[],
		currencyOverride?: string,
		includeClosedAccounts?: boolean,
		accountTagIds?: string[],
		excludeAccountTagIds?: string[],
		accountTypes?: string[],
		tqlJSONExpression?: object
	): Observable<HttpResponse<AccountsObjectV3>> {
		// TODO: handle 'tags' and 'excludeTags' v5 API params
		const maxAccountsList = 1000;

		const body: object = {};
		if (accountIds) {
			body['accountId'] = accountIds;
		}

		if (excludeAccountIds) {
			body['excludeAccountId'] = excludeAccountIds;
		}

		if (accountTagIds) {
			body['accountTagId'] = accountTagIds;
		}

		if (excludeAccountTagIds) {
			body['excludeAccountTagId'] = excludeAccountTagIds;
		}

		if (accountTypes) {
			body['accountType'] = accountTypes;
		}

		if (fullAccountNumbers) {
			body['includeFullAccountNumbers'] = fullAccountNumbers;
		}

		if (currencies) {
			body['currency'] = currencies;
		}

		if (currencyOverride) {
			body['currencyOverride'] = currencyOverride;
		}

		body['start'] = start || 0;
		body['from'] = 0;
		body['size'] = maxAccountsList;

		if (includeClosedAccounts !== undefined) {
			body['isClosed'] = includeClosedAccounts;
		}

		if (tqlJSONExpression) {
			const expression: object = tqlJSONExpression;
			body['tql'] = { type: 'AST', expression };
		}

		const url: string = `${environment.trovataAPI('workspace')}/data/v5/accounts`;

		return this.httpClient
			.post<AccountsObjectV3>(url, body, {
				observe: 'response',
			})
			.pipe(
				map(result => result),
				switchMap(result => {
					const totalAccounts = result.body.totalAccounts;
					let accountsLength = 0;
					const requests: Observable<HttpResponse<AccountsObjectV3>>[] = [];
					const firstResult = of(result);
					requests.push(firstResult);
					if (totalAccounts > maxAccountsList) {
						for (let i = maxAccountsList; i < totalAccounts && (!size || accountsLength < size); i += maxAccountsList) {
							const reqBody = JSON.parse(JSON.stringify(body));
							reqBody['from'] = i;
							const currentLength = size ? Math.min(size - accountsLength, maxAccountsList) : maxAccountsList;
							reqBody['size'] = currentLength;
							accountsLength += currentLength;
							requests.push(
								this.httpClient
									.post<AccountsObjectV3>(url, reqBody, {
										observe: 'response',
									})
									.pipe(catchError(err => throwError(err)))
							);
						}
					}
					return forkJoin(requests);
				}),
				map(responses => {
					const firstGroup = responses[0];
					if (responses.length > 1) {
						responses.forEach((response, index) => {
							if (index > 0) {
								firstGroup.body.accounts = [...firstGroup.body.accounts, ...response.body.accounts];
							}
						});
					}
					return firstGroup;
				}),
				catchError(err => throwError(err))
			);
	}
}
